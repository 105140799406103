<template>
  <div class="">
    <div class="categories" id="categorias">
      <div class="col-12 row">
        <div class="col-6">
          <img width="550" src="../../../public/images/sobre.png" alt="">
        </div>
        <div class="col-6">
          <div
                class="row gx-4 gx-lg-5 h-100 align-items-left justify-content-left text-left ml-4 container-escrita-banner"
              >
                <div class="col-lg-8 align-self-end text-left">
                  <h6 class="titulo-superior ">SOBRE O GCLASS </h6>
                  <h1 class="title-principal text-white font-weight-bold">
                    Nesta plataforma você conhecerá todos os sistemas da inove.
                  </h1>
                  <!-- <hr /> -->
                </div>
                <div class="col-lg-8 align-self-baseline">
                  <p class="text-white-75 mb-4 subtitle">
                    Nossa equipe está constantemente atualizando o GClass, com tutoriais e vídeo aulas para melhor lhe atender
                    e orientar como executar processos dentro dos nossos sitemas!
                  </p>
                  <p class="text-white-75 mb-2 subtitle">
                    <i class="bi bi-check-circle-fill"></i> Plataforma rápida para solução de seus problemas;
                  </p>
                  <p class="text-white-75 mb-2 subtitle">
                    <i class="bi bi-check-circle-fill"></i> Vídeos intuitivos clique a clique;
                  </p>           
                  <p class="text-white-75 mb-2 subtitle">
                    <i class="bi bi-check-circle-fill"></i> Atualização constante com mais tutoriais;
                  </p>           
                </div>

              </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" src="./style.scss" scoped />