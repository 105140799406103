<template>
  <div id="app">
    <nav-menu></nav-menu>
    <router-view/>
  </div>
</template>
<script>
import NavMenu from './components/NavMenu/NavMenu.vue';

export default {
  components: {
    NavMenu,
  }
}
</script>

<style>
#app {
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

</style>
