<template>
    <div>
        <cursos-painel></cursos-painel>
    </div>
</template>
<script>
import CursosPainel from '@/components/Painel/CursosPainel/CursosPainel.vue';
export default {
    components: {
        CursosPainel
    }
}
</script>

<style lang="scss" src="./style.scss" scoped />
