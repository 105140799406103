<template>
    <div>
        <form @submit.prevent="submitFiles">
            <div class="dropbox">
                <b-form-file @change="onFileChange($event)" placeholder="Inserir anexos" multiple ref="file-input"
                    class="mb-2" v-model="selectFiles" :state="Boolean(selectFiles)" :file-name-formatter="formatNames"
                    id="formFiles"></b-form-file>
            </div>
            <button class="btn btn-success d-none" type="submit" id="btnFormFiles">Enviar Anexos</button>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            files: [],
            selectFiles: [],
        }
    },
    methods: {
        formatNames(files) {
            return files.length === 1 ? files[0].name : `${files.length} arquivos selecionados`
        },
        onFileChange(event) {
            console.log(event.target.files)
            this.files = event.target.files
            this.$store.commit('Painelvideos/setFilesUpload', event.target.files)
        },
        submitFiles() {
            const formData = new FormData();

            for (let i = 0; i < this.files.length; i++) {
                formData.append('files[]', this.files[i]);
            }

            this.$store.dispatch(`os/uploadFiles`, { data: formData, nrSeqOs: this.$route.params.id }).then(response => {
                // this.$swal.fire({
                //     title: 'Sucesso!',
                //     text: 'Anexos cadastrados com sucesso!',
                //     icon: 'success'
                // })
            }).catch(error => {
                console.log(error)
                this.$swal.fire({
                    title: 'Oops!',
                    text: 'Falha ao cadastrar anexos, tente novamente!',
                    icon: 'error'
                })
            })
        }
    }
}
</script>

<style>
.custom-file-input:lang(en)~.custom-file-label::after {
    content: 'Escolher Arquivos';
}

.custom-file-label {
    border: 1px solid #ced4da!important;
    border-radius: 25px!important;
    position: absolute!important;
    left: 51%;
    padding: 0.375rem 0.75rem!important;
    line-height: 1.5;
    color: #495057;
    cursor: pointer;
}

#formFiles {
    opacity: 0;
}

</style>