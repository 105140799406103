<template>
  <div class="col-12" id="contato">
    <div class="col-12 row section-novidades">
      <div class="col-12">
        <h6 class="titulo-superior">CONTATO</h6>
        <h1 class="text-left title-novidades mt-2 mb-4">
          Entre em contato e vamos
          <span class="palavras-coloridas">conversar!</span>
        </h1>
      </div>
      <hr />
      <div class="col-12 row mt-3">
        <div class="col-6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6893.428556231141!2d-52.02189935841159!3d-27.228231614999267!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94e38162aa99bceb%3A0x5038cbf4529f7aeb!2sInove%20Sistemas%20e%20Solu%C3%A7%C3%B5es%20Empresariais!5e0!3m2!1spt-BR!2sbr!4v1684425021062!5m2!1spt-BR!2sbr"
            width="500"
            height="550"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div class="col-6 text-left div-contato">  
          <div class="col-12 row">
            <div class="col-12 row">
              <div class="col-4">
                <p class="palavras-coloridas"><i class="bi bi-telephone"></i> Telefone:</p>
              </div>
              <div class="col-8">
                <p class="informacoes-contato"> (49) 3425-2597</p>
              </div>
            </div>

            <div class="col-12 row">
              <div class="col-4">
                <p class="palavras-coloridas"><i class="bi bi-envelope"></i> E-mail: </p>
              </div>
              <div class="col-8">
                <p class="informacoes-contato"> financeiro@i9ss.com.br</p>
              </div>
            </div>

            <div class="col-12 row">
              <div class="col-4">
                <p class="palavras-coloridas"><i class="bi bi-geo-alt"></i> Endereço: </p>
              </div>
              <div class="col-8">
                <p class="informacoes-contato"> Rua Dr. Maruri 1360 sala207, Concórdia, SC, Brasil</p>
              </div>
            </div>

            <div class="col-12 row">
              <div class="col-4">
                <p class="palavras-coloridas" style="width: 190px!important"><i class="bi bi-whatsapp"></i> WhatsApp: </p>

              </div>
              <div class="col-8">
                <p class="informacoes-contato">  (49) 9169-8966</p>
              </div>
            </div>
            
          </div>
          <div class="col-12 row">
          </div>
          <div class="col-12 row">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { text } from "@fortawesome/fontawesome-svg-core";

export default { components: { text } };
</script>
  <style lang="scss" src="./style.scss" scoped />
  