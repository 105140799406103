import api from '@/common/api.js'

export default {
  namespaced: true,

  state: {
    filesUpload: ''
  },
  getters: {
  },
  actions: {
    async uploadFiles({ commit, state }, { data, nr_seq_curso }){
      try{
        const response = await api.post(`painel/video/uploadArquivos/${nr_seq_curso}`, data)

        return response
      } catch (error){
        throw Error(error)
      }
    },
    async submitUpdated({ commit }, { data }) {
        try {
            const response = api.post('painel/video/submitUpdated', { ...data })

            return response
        } catch (error) {
            throw new Error(error)
        }
    },

    async submidCreated({ commit }, { data }) {
      try {
        const response = api.post('painel/video/submidCreated', { ...data })

        return response
      } catch (error) {
          throw new Error(error)
      }
    },

    async deletedVideo({ commit }, { data }) {
      try {
        const response = api.post('painel/video/deletedVideo', { ...data })

        return response
      } catch (error) {
          throw new Error(error)
      }
    }
  },
  mutations: {
    setFilesUpload(state, files) {
      state.filesUpload = files
    },
  },
}
